import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'

import img from '../assets/images/error.png'

const NotFoundPage = () => (

  <Layout>
    <ErrorPageWrapper>
      <img className="img" src={img}></img>
      <div className="error">
        <h1>ERROR NOT FOUND</h1>
        <h3 className="button primary">
          <a href="/">Please try again</a>
        </h3>
      </div>
    </ErrorPageWrapper>
  </Layout>
)

const ErrorPageWrapper = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    border-radius: 0.375em;
		border: 0;
		box-shadow: inset 0 0 0 2px #f56a6a;
		color: #f56a6a !important;
    /* height: 3.5em; */
		letter-spacing: 0.075em;
		line-height: 3.5em;
		padding: 0 2.25em;
  }

  .img {
    float: left;
    width: 7rem;
    height: 7rem;
  }

  .error {
    float: right;
    text-align: center;
    padding-left: 2rem;
  }
`

export default NotFoundPage
